import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

export default ({ location }) => (
    <Layout>
        <SEO pagetitle="お問い合わせありがとうございます。" pagepath={location.pathname} />
        <main id="contact">
            <div className="inner">
                <h1>Thank you.
                    <span>お問い合わせありがとうございました。</span>
                </h1>
                <p className="thankyouText">ご返事まで2,3営業日ほどお待ち下さい。</p>
                <div className="thankyouButton"><Link to={`/`}>トップページへ</Link></div>
            </div>
        </main>
    </Layout>
)